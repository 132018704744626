<template>
  <div class="item_box" :class="{ item_box_c: type != 1 }">
    <div class="item_box_l">
      <div class="num_box" v-if="type == 1">
        ￥<span>{{ money }}</span>
      </div>
      <div class="num_box" v-else>
        +<span>{{ money }}</span
        >次
      </div>
      <!-- <div class="num_info" v-if="type == 1">满{{usage_limit}}可用</div> -->
    </div>
    <div class="item_box_r">
      <div class="r_title">{{ title }}</div>
      <div class="r_rule" v-if="type == 1">{{ rule }}</div>
      <div class="r_timer" v-if="type == 1">
        使用范围:金额满{{ usage_limit }}元 - {{ usage_limit_upper }}元的门票
      </div>
      <div class="r_timer" v-else>
        使用范围:仅限次票{{ usage_limit }}次 -
        {{ usage_limit_upper }}次的门票可用
      </div>
      <div class="r_timer">
        有效期:{{ timestampToDate(time_start) }}~{{ timestampToDate(time_end) }}
      </div>
      <div v-show="stats == 2 && order_sn" class="r_timer">
        使用订单：{{ order_sn }}
      </div>
    </div>
    <div class="icon">
      <img v-show="stats == 1 && !isExpired(time_end)" src="../assets/c_1.png" alt="" />
      <img v-show="stats == 2" src="../assets/c_2.png" alt="" />
      <img v-show="stats == 1 && isExpired(time_end)" src="../assets/c_3.png" alt="" />
      <img v-show="stats == 4" src="../assets/c_4.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    usage_limit: Number,
    usage_limit_upper: Number,
    money: Number,
    type: Number,
    stats: Number,
    title: String,
    rule: String,
    time_start: Number,
    time_end: Number,
    order_sn: String,
  },
  data() {
    return {};
  },
  methods: {
    timestampToDate(timestamp) {
      var date = new Date(timestamp * 1000); // 注意JS中的时间戳为秒数，因此需要将其乘以1000
      var year = date.getFullYear(); // 获取年份，四位数
      var month = ("0" + (date.getMonth() + 1)).slice(-2); // 获取月份，并用slice函数确保结果为两位数
      var day = ("0" + date.getDate()).slice(-2); // 获取日期，并用slice函数确保结果为两位数
      return `${year}-${month}-${day}`; // 返回格式为YYYY-MM-DD的日期字符串
    },
    isExpired(timestamp) {
      var currentTimestamp = Date.now();
      console.log(timestamp)
      if (currentTimestamp > timestamp*1000) {
        return true; // 过期
      }

      return false; // 未过期
    },
  },
};
</script>
<style scoped>
.item_box {
  width: 350rem;
  background-size: 100% 100%;
  margin: auto;
  padding: 18rem 14rem;
  box-sizing: border-box;
  margin-top: 10rem;
  display: flex;
  align-items: center;
  position: relative;
  background: #fff;
  border-radius: 18rem;
}
.item_box_c .item_box_l {
  background-image: url(../assets/c_bg_02.png);
}
.item_box_l {
  width: 85rem;
  height: 85rem;
  background-image: url(../assets/c_bg_01.png);
  background-size: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.num_box {
  height: 26rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10rem;
  color: #ff805c;
}
.item_box_c .num_box {
  color: #5699e8;
}
.num_box span {
  font-size: 26rem;
}
.num_info {
  color: #fff;
  text-align: center;
}
.item_box_r {
  margin-left: 25rem;
  font-size: 12rem;
  line-height: 16rem;
  color: #7f7f7f;
}
.r_title {
  color: #171746;
  font-weight: bold;
  font-size: 16rem;
  margin-bottom: 12rem;
}
.icon {
  width: 70rem;
  right: 10rem;
  top: 10rem;
  position: absolute;
}
.icon img {
  width: 100%;
}
.r_timer{
  line-height: 22rem;
}
</style>
