import ticketCode from '../../components/ticketCode.vue'
import couponItem from '../../components/couponItem.vue' //优惠券
export default {
    name: 'coupon_list',
    components: {
        ticketCode,
        couponItem
    },
    data() {
        return {
            active: 0,
            stateData: [{
                    name: '未使用',
                    page: 0,
                    list: []
                },
                {
                    name: '已使用',
                    page: 0,
                    list: []
                },
                {
                    name: '已过期',
                    page: 0,
                    list: []
                }
            ],
            refreshing: false, //下拉刷新
            error: false, //是否加载成功
            loading: false,
            finished: false,
            tickeShow: false, //显示二维码
            id: null,
            is_pop: 0,
            order_id: null
        }
    },
    watch: {
        active(val) {
            this.onRefresh()
        }
    },
    created() {
        document.title = "券包";
        console.log(localStorage.getItem('mall_id'))
    },
    methods: {
        pop(id) {
            this.order_id = id;
            this.is_pop = 1;
        },
        //退款
        tk(is) {
            if (is) {
                this.$UtilsApi.orderRefund({
                    id: this.order_id,
                }).then(res => {
                    if (res.data.code == 0) {
                        this.is_pop = 2;
                        this.refreshing = true;
                        this.stateData.forEach((item, index) => {
                            item.list = [];
                            item.page = 0;
                        });
                        this.$forceUpdate;
                        this.onRefresh();
                    } else {
                        this.$notify({ type: 'danger', message: '退款失败,请联系工作人员', duration: 2000 });
                    }
                });
                console.log('退款')
            }
            this.is_pop = 0;
        },
        // 根据时间戳转换日期
        getNewDate(timer) {
            var timestr = new Date(timer)
            var year = timestr.getFullYear();
            var month = timestr.getMonth() + 1;
            var date = timestr.getDate();
            return year + "." + (month < 10 ? '0' + month : month) + "." + (date < 10 ? '0' + date : date);
        },
        onRefresh() {
            // 清空列表数据
            this.finished = false;
            this.loading = true;
            this.onLoad();
        },
        onLoad() {
            if (this.refreshing) {
                this.refreshing = false;
                this.stateData[this.active].page = 0;
            }
            this.$UtilsApi.coupon_my({
                status: ['unuse', 'used', 'expire'][this.active],
                page: this.stateData[this.active].page
            }).then(res => {
                if (res.data.code == 0) {
                    console.log(res.data.data)
                    if (this.stateData[this.active].page == 0) {
                        this.stateData[this.active].list = [];
                    }
                    res.data.data.forEach(item => {
                        this.stateData[this.active].list.push(item)
                    });
                    // console.log(this.stateData[this.active].list)
                    this.stateData[this.active].page++;
                    this.loading = false;

                    if (res.data.data.length < 20) {
                        this.finished = true;
                    }
                } else {
                    this.$notify({ type: 'warning', message: res.data.msg, duration: 2000 });
                }
            });
        },
        //判断订单是否过期
        getTimerState(order_time) {
            var timestamp = Date.parse(new Date()) / 1000;
            return timestamp - order_time
        },
        //去订单详情
        toOrderInfo(id) {
            this.$router.push({
                name: 'orderInfo',
                query: {
                    id: id
                }
            })
        },
        //出示二维码
        playSY(id) {
            this.id = id;
            this.tickeShow = true;
        },
        //关闭二维码弹窗
        IsCode(e) {
            this.tickeShow = false;
        },
        // 支付
        payment(data) {
            let _this = this;
            if (typeof WeixinJSBridge == 'undefined') return;

            this.$UtilsApi.payment(data.id, {}).then(res => {
                let paymentData = JSON.parse(res.data.data.payment);
                WeixinJSBridge.invoke('getBrandWCPayRequest', {
                    "appId": _this.$appId, //公众号ID，由商户传入    
                    "timeStamp": paymentData.timestamp, //时间戳，自1970年以来的秒数    
                    "nonceStr": paymentData.nonceStr, //随机串    
                    "package": paymentData.package,
                    "signType": paymentData.signType, //微信签名方式：    
                    "paySign": paymentData.paySign //微信签名
                }, function(res) {
                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                        _this.$router.push({
                            name: 'payState',
                            query: {
                                type: 'ok',
                                order_id: data.id,
                                price: data.price
                            }
                        })

                    } else if (res.err_msg == "get_brand_wcpay_request:fail") {
                        _this.$router.push({
                            name: 'payState',
                            query: {
                                type: 'no',
                                order_id: data.id,
                                price: data.price
                            }
                        })

                    } else {
                        _this.$router.push({
                            name: 'payState',
                            query: {
                                type: 'qx',
                                order_id: data.id,
                                price: data.price
                            }
                        })
                    }
                });
            })

        }
    },
}